<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/lessonschedule/camp' }">训练营列表</el-breadcrumb-item>
                <el-breadcrumb-item>训练营详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div>
                <div style="height: 20px;"></div>
                <!--详情-->
                <el-form class="time-arrange-form" v-loading="loading" size="medium" :label-width="this.env.label_width">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="选择门店">
                                <span class="form-span-text">{{ info.store_name }}</span>
                            </el-form-item>
                            <el-form-item label="选择教室">
                                <span class="form-span-text">{{ info.classroom_name }}</span>
                            </el-form-item>
                            <el-form-item label="选择教练">
                                <span class="form-span-text">{{ info.coach_uuid ? info.coach_user_nick : '无教练'
                                }}</span>
                            </el-form-item>
                            <el-form-item label="选择课程">
                                <span class="form-span-text">{{ info.title }}</span>
                            </el-form-item>
                            <el-form-item label="课程标题">
                                <span class="form-span-text">{{ info.lesson_name }}</span>
                            </el-form-item>
                            <el-form-item label="容纳人数">
                                <span class="form-span-text">{{ info.capacity }}人/{{ info.area }}平</span>
                            </el-form-item>
                            <el-form-item label="课程人数">
                                <span class="form-span-text">{{ info.lesson_quota }}人</span>
                            </el-form-item>
                            <el-form-item label="人数上限">
                                <span class="form-span-text">{{ info.quota }}人 </span>
                                <p v-if="info.bookings_sum">已预约:{{ info.bookings_sum }}人</p>
                            </el-form-item>
                            <el-form-item label="最少开课人数">
                                <span class="form-span-text">{{ info.bookings_min }}</span>
                            </el-form-item>
                            <el-form-item label="提前N小时确定">
                                <span class="form-span-text">开课前{{ info.determine_time }}小时确定是否达到最小开课人数。未达到则自动取消。0不设置</span>
                            </el-form-item>
                            <el-form-item label="课程时长">
                                <span class="form-span-text">{{ info.lesson_duration }}分钟</span>
                            </el-form-item>
                            <el-form-item label="原价">
                                <span class="form-span-text">¥{{ info.originally_price }}</span>
                            </el-form-item>
                            <el-form-item label="售价">
                                <span class="form-span-text">¥{{ info.price }}</span>
                            </el-form-item>
                            <el-form-item label="课程数量">
                                <span class="form-span-text">{{ info.lesson_sum }}</span>
                            </el-form-item>
                            <el-form-item label="上课时间">
                                <div class="lesson_time" v-for="(item, index) in info.lesson_time" :key="index">
                                    <span class="item_lesson_time">第{{ index + 1 }}节</span>
                                    <span class="form-span-text" style="margin-left: 12px;">{{ item }}</span>
                                </div>

                            </el-form-item>

                            <el-form-item label="排队等候">
                                <span class="form-span-text">{{ info.is_queue_up === 1 ? '允许等候' : '不允许等候' }}</span>
                            </el-form-item>
                            <el-form-item label="允许退款">
                                <span class="form-span-text">{{ info.is_refund === 1 ? '允许退款' : '不允许退款' }}</span>
                                <template v-if="info.is_refund === 1">
                                    <div class="form-tip">1.开课前{{ info.refund_time }}小时外取消，全额退款</div>
                                    <div class="form-tip">2.开课前{{ info.refund_time }}小时内仅取消订单不退款</div>
                                </template>
                            </el-form-item>
                            <el-form-item label="上架时间(选填)">
                                <span class="form-span-text">{{ info.publish_time }}</span>
                            </el-form-item>
                            <el-form-item label="提示(选填)">
                                <span class="form-span-text">{{ info.tip }}</span>
                            </el-form-item>
                            <el-form-item label="适用范围">
                                <span class="form-span-text">{{ info.range === 1 ? '全部用户' : '新用户专享' }}</span>
                            </el-form-item>
                            <el-form-item>
                                <el-form-item>
                                    <el-button @click="isreturn()" size="medium" type="primary">返回
                                    </el-button>
                                </el-form-item>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '训练营详情',
            loading: false,                 // 加载状态
            good_uuid: '',                  // 课表uuid
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    methods: {
        // 获取排课信息
        getInfo(good_uuid = '') {
            let postdata = {
                api_name: "lessonschedule.camp.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data

                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let good_uuid = this.$route.query.good_uuid
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getInfo(good_uuid)
            } else {
                this.loading = false
            }
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },

    }
}
</script>
